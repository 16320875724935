<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    title="巡检内容详情"
    top="2vh">
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="content"
        label="内容"
      >
      </el-table-column>
      <el-table-column
        prop="requirement"
        label="要求"
      >
      </el-table-column>
      <el-table-column
        prop="checkResultTypeDesc"
        label="巡检情况">
      </el-table-column>
    </el-table>
    <!--    <el-divider></el-divider>-->
    <el-card :body-style="{ padding: '0px' }">
      <img :src="ip+signature" class="image"/>
      <div style="padding: 14px;">
        <div class="bottom clearfix">
          <time class="time">签字时间{{updateTime}}</time>
        </div>
      </div>
    </el-card>
  </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        ip:window.config.pictureUrl,
        signature:"",
        updateTime:"",
        tableData:[],
      };
    },
    methods: {
      open(id) {
        this.id = id;
        this.dialogVisible = true;
        this.getInspectionDtail(id);

      },
      getInspectionDtail(id) {
        this.contentLoading = true;
        this.$http.get(`/property/routing-inspection/${id}`).then(data => {
          this.tableData = data.items;
          this.signature = data.signature;
          this.updateTime = data.updateTime;
          console.log(data,49);
          // return data;
        }).then(data => {
          this.getElevatorData(data);

        });
      },

    },
  };
</script>
<style lang="scss" scoped>
.gr-descriptions{
  margin-bottom: 10px;
}
.gr-fault-card{
  width: 260px;
  display: inline-block;
  margin: 10px;
}
.gr-timeline{
  margin: 10px;
}
</style>
