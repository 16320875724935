<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="property/customer/routing-inspections/page">
      <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.createPage.open()">发起巡检</el-button>-->
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('elevator.realEstateName','楼盘')">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.name','楼宇')">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevator.name','电梯名称')">
            <el-input v-model.trim="filter.elevatorName" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('device.no','注册代码')">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>

          <vm-search label="巡检人员">
            <el-input v-model.trim="filter.workerName" clearable></el-input>
          </vm-search>
          <vm-search label="巡检时间起始">
            <el-date-picker v-model.trim="filter.updateTimeFrom" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
          </vm-search>
          <vm-search label="巡检时间截止">
            <el-date-picker v-model.trim="filter.updateTimeTo" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" clearable></el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" :label="$l('elevator.name','楼盘')" align="center"></el-table-column>
      <el-table-column prop="buildingName" :label="$l('elevator.name','楼宇')" align="center"></el-table-column>
      <el-table-column prop="elevatorName" :label="$l('elevator.name','电梯名称')" align="center"></el-table-column>
      <el-table-column prop="regCode" :label="$l('device.no','注册代码')" align="center" width="170"></el-table-column>
      <!--      <el-table-column prop="deviceNo" :label="$l('device.no','终端编号')" align="center" width="155"></el-table-column>-->
      <el-table-column prop="workerName" :label="$l('project.name','巡检人员')" align="center"></el-table-column>
      <!--      <el-table-column prop="createBy" label="创建人" align="center"></el-table-column>-->
      <!--      <el-table-column prop="repairmanName" :label="$l('repair.repairmanName','巡检人员')" align="center"></el-table-column>-->
      <el-table-column prop="updateTime" :label="$l('repair.createTime','巡检时间')" align="center"></el-table-column>
      <el-table-column
        :label="$l('common.function','操作')"
        fixed="right"
        align="center"
        class-name="vm-table_operate"
        width="180">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.detailPage.open(scope.row.id)">详情</el-button>
          <!--          <el-button type="primary" @click.native="$refs.verificationPage.open(scope.row.id,scope.row.reportRemark)">提交巡检报告</el-button>-->
        </template>
      </el-table-column>
    </vm-table>
    <el-dialog
      :visible="dialogVisible"
    >
      <el-table
        :data="tableData"
        style="width: 100%">
        <el-table-column
          prop="content"
          label="内容"
        >
        </el-table-column>
        <el-table-column
          prop="requirement"
          label="要求"
        >
        </el-table-column>
        <el-table-column
          prop="address"
          label="完成情况">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <repair-detail ref="detailPage"></repair-detail>
  </div>
</template>
<script>
  import RepairDetail from "./InspectionDetail";

  export default {
    components: {RepairDetail},
    data() {
      return {
        useOrgName:null,
        maintProjectName:null,
        dialogVisible:false,
        filter: {
          deviceNo:null,
          useOrgId:null,
          maintProjectId:null,
          name:null,
          regCode:null,
          status:null,
          createBy:null,
          reporterNameOrPhone:null,
          repairmanName:null,
          createTimeStart:null,
          createTimeEnd:null,
        },
        tableData:[],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      detailModal(arr) {
        this.tableData=arr instanceof Array?arr:[];
        this.dialogVisible=true;
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      cancelOrder(repairOrderId) {
        this.$prompt("此操作将取消维修工单, 请注入备注", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(({value}) => {
          this.$http
            .post("repair/"+repairOrderId+"/cancellation",value)
            .then(() => {
              this.$message.success("维修工单取消成功");
              this.getList(-1);
            }).catch(() => {
              this.$message.error("维修工单取消失败");
            });
        }).catch(() => {
          this.$message.info("取消对维修工单的取消操作");
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
